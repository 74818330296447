<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div class="app-fixed-search d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="tabSelectFilter === 'sent' ? listview_txt_default.txt_search_sent : listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="tabSelectFilter === 'received' && filterSelected.estadoReceived.value !== 17 && fieldsOrder.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-if="tabSelectFilter === 'sent' && filterSelected.estadoSent.value !== 18 && fieldsOrderSent.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrderSent"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBySent(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBySent(oField.name)"
                :class="listviewIconOrderBySent(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-if="tabSelectFilter === 'approve' && fieldsOrderApprove.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrderApprove"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderByApprove(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderByApprove(oField.name)"
                :class="listviewIconOrderByApprove(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-if="(tabSelectFilter === 'reserve' || (tabSelectFilter === 'sent' && filterSelected.estadoSent.value === 18) || (tabSelectFilter === 'received' && filterSelected.estadoReceived.value === 17)) && fieldsOrderReserve.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrderReserve"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderByReserve(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderByReserve(oField.name)"
                :class="listviewIconOrderByReserve(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Listagem -->
      <div class="email-user-list scroll-area">
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
          @scroll.native="listViewSavePositionScroll"
        >
          <listview-list-data
            v-if="listviewData.length > 0"
            ref="list"
            :show-msg-error-request="showMsgErrorRequest"
            :set-proposal-detail="setProposalDetail"
          />
          <div
            v-if="listviewData.length === 0"
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(filterSelected.totalFields.value === 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="(filterSelected.totalFields.value > 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt_not_find }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['crm_proposals/loadingListview']"
        no-wrap
      />

      <listview-list-visits
        ref="listVisits"
        :class="{'show': showListVisits}"
        :show-msg-error-request="showMsgErrorRequest"
        :open-modal-detail-by-row="openModalDetailByRow"
        :open-left-sidebar="openLeftSidebar"
        @close-listview="showListVisits=false"
      />

      <proposal-detail
        ref="detailProposal"
        :class="{'show': showDetail}"
        :show-msg-error-request="showMsgErrorRequest"
        :show-msg-success-request="showMsgSuccessRequest"
        :open-left-sidebar="openLeftSidebar"
        @close-detail="closeModalDetail"
      />

      <form-new-proposal
        ref="formProposal"
        :class="{'show': showForm}"
        @save-new-proposal="saveNewProposal"
        @cancel-new-proposal="cancelNewProposal"
        @close-form="cancelNewProposal"
      />

    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :show-msg-error-request="showMsgErrorRequest"
        :cancel-reservation="cancelReservation"
        :cancel-proposal="cancelProposal"
        :counter-proposal="counterProposal"
        :upload-documents="uploadDocuments"
        :get-activity-proposal="getActivityProposal"
        :back-to-visitas="backToVisitas"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @cancel-new-proposal="cancelNewProposal"
        @save-new-proposal="saveNewProposal"
      />
    </portal>

    <visit-modal-detail
      v-if="showProcessoModalDetail===true"
      ref="processoModalDetail"
      :row="rowModalAction"
      :separador="Number(tabSelectFilterVisits)"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <form-accept-proposal
      v-if="showFormAcceptProcess===true"
      ref="formAcceptProcesso"
      :accept-type="acceptType"
      :upload-docs="uploadDocs"
      :submit-accept-proposal="submitAcceptProposal"
      :check-proposals-state="checkProposalsState"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <form-refuse-proposal
      v-if="showFormRefuseProcess===true"
      ref="formRefuseProposal"
      :title-refuse="titleRefuse"
      :submit-refuse-proposal="submitRefuseProposal"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <form-cancel-proposal
      v-if="showFormCancelProposal===true"
      ref="formCancelProposal"
      :submit-cancel-proposal="submitCancelProposal"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <form-counter-proposal
      v-if="showFormCounterProposal===true"
      ref="formCounterProposal"
    />

    <form-upload-docs-proposal
      v-if="showFormUploadDocs===true"
      ref="formUploadDocsProposal"
      :submit-docs-proposal="submitDocsProposal"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <activity-proposal
      v-if="showFormActivity===true"
      ref="formActivityProposal"
    />

    <component
      :is="ModalRefuseProposal"
      v-if="showModalRefuseProposal === true"
      ref="modalRefuseProposal"
      :modal-data-refuse="modalDataRefuse"
      :count-proposal="countProposal"
      :submit-refuse-proposal="submitRefuseProposal"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />
  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted, computed } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
  VBTooltip, BDropdown, BDropdownItem, BRow, BCol, BContainer, BPagination, BOverlay,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import crmProposalsListModule from '@store-modules/crm/proposals/listview'
import crmProposalsEventsModule from '@store-modules/crm/proposals/listview/events'
import crmModule from '@store-modules/crm/visitas/listview'
import { mapGetters } from 'vuex'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import Ripple from 'vue-ripple-directive'
import { useRouter, showModalMessage } from '@core/utils/utils'
import VuePullRefresh from 'vue-pull-refresh'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    VuePullRefresh,
    listviewLeftSidebar: () => import(/* webpackChunkName: "crm-proposals-listview-sidebar" */ /* webpackPrefetch: true */ './leftSidebar.vue'),
    listviewListData: () => import(/* webpackChunkName: "crm-proposals-listview-data" */ /* webpackPrefetch: true */ './listData.vue'),
    listviewListVisits: () => import(/* webpackChunkName: "crm-proposals-listview-visits" */ /* webpackPrefetch: true */ './listviewVisits.vue'),
    proposalDetail: () => import(/* webpackChunkName: "crm-proposals-detail" */ /* webpackPrefetch: true */ './detail.vue'),
    visitModalDetail: () => import(/* webpackChunkName: "crm-visits-detail" */ /* webpackPrefetch: true */ '../../visitas/listview/modalDetail.vue'),
    formNewProposal: () => import(/* webpackChunkName: "crm-proposals-new" */ /* webpackPrefetch: true */ '../form/index.vue'),
    formAcceptProposal: () => import(/* webpackChunkName: "crm-proposals-detail-modal-accept" */ /* webpackPrefetch: true */ '../form/modal/accept.vue'),
    formRefuseProposal: () => import(/* webpackChunkName: "crm-proposals-detail-modal-refuse" */ /* webpackPrefetch: true */ '../form/modal/refuse.vue'),
    formCancelProposal: () => import(/* webpackChunkName: "crm-proposals-detail-modal-cancel" */ /* webpackPrefetch: true */ '../form/modal/cancel.vue'),
    formCounterProposal: () => import(/* webpackChunkName: "crm-proposals-detail-modal-counter" */ /* webpackPrefetch: true */ '../form/modal/counter.vue'),
    activityProposal: () => import(/* webpackChunkName: "crm-proposals-detail-modal-activity" */ /* webpackPrefetch: true */ './activity.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  props: {
    createNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    idvisita: {
      type: String,
      required: false,
      default: null,
    },
    modeView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showProcessoModalDetail: false,
      showFormAcceptProcess: false,
      showModalRefuseProposal: false,
      showFormRefuseProcess: false,
      showFormCancelReservation: false,
      showFormCancelProposal: false,
      showFormCounterProposal: false,
      showFormUploadDocs: false,
      showFormActivity: false,
      rowModalAction: {},
      modalDataRefuse: {},
      refuseMultiple: 0,
      countProposal: 0,
      titleRefuse: '',
      acceptType: '',
      backToVisitas: false,
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['listviewData', 'filterData', 'filterSelected', 'listviewOrderBy', 'listviewOrderBySent', 'listviewOrderByApprove', 'listviewOrderByReserve', 'totalRows', 'perPage', 'listview_txt_default', 'listviewInit',
      'fieldsOrder', 'fieldsOrderSent', 'fieldsOrderApprove', 'fieldsOrderReserve', 'tabSelectFilter', 'positionListview', 'proposalDetail', 'tabSelectFilterVisits', 'showPager']),
    searchTxt: {
      get() { return this.$store.getters['crm_proposals/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('crm_proposals/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['crm_proposals/currentPage'] },
      set(newValue) {
        this.$store.dispatch('crm_proposals/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    showListVisits: {
      get() { return this.$store.getters['crm_proposals/showListVisits'] },
      set(newValue) {
        this.$store.dispatch('crm_proposals/setShowListVisits', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    showDetail: {
      get() { return this.$store.getters['crm_proposals/showDetail'] },
      async set(newValue) {
        await this.$store.dispatch('crm_proposals_events/clearSWkeepalive', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01) }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.dispatch('crm_proposals/setShowDetail', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    showForm: {
      get() { return this.$store.getters['crm_proposals/showForm'] },
      async set(newValue) {
        await this.$store.dispatch('crm_proposals/setShowForm', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
    '$route.query': {
      immediate: false,
      handler(newVal) {
        this.loadFilterCustomQuery(newVal, true)
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('crm_proposals/savePositionListview', this.sPostionListview)
    this.$store.commit('crm_proposals/saveStates')
  },
  async created() {
    try {
      const { idvisita } = this.paramsUrl
      const { idproposta } = this.paramsUrl

      await this.$store.commit('crm_proposals/setHistoryKeyStorage', this.$route.name)

      await this.$store.dispatch('crm_proposals/getDataFiltersListview', { tab: this.tabSelectFilter }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.loadFilterCustomQuery(this.routeParamsQuery, false)

      await this.$store.dispatch('crm_proposals/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }

      if (this.createNew === true && (idvisita === undefined || idvisita === null)) {
        this.$refs.leftSidebar.createNewProposals()
      } else if (this.createNew === true && (idvisita !== undefined && idvisita !== null)) {
        this.backToVisitas = true
        await this.$store.dispatch('crm_proposals_form/getDataForm', idvisita).then(result => {
          if (result === true) {
            this.$store.dispatch('crm_proposals/setShowForm', true).catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.$store.dispatch('crm_proposals/setShowListVisits', true).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }

      if (this.modeView === true && (idproposta !== undefined && idproposta !== null)) {
        this.backToVisitas = true
        await this.$store.dispatch('crm_proposals/previewModalProposal', { sw130s01: idproposta, action: this.tabSelectFilter }).then(result => {
          if (result) {
            this.$store.dispatch('crm_proposals/setProposalDetail', { showDetail: true }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    } catch (err) {
      this.showMsgErrorRequest(err)
    }
  },
  methods: {
    listviewFilterOrderBy(field) {
      this.$store.dispatch('crm_proposals/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterOrderBySent(field) {
      this.$store.dispatch('crm_proposals/listviewFilterOrderBySent', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterOrderByApprove(field) {
      this.$store.dispatch('crm_proposals/listviewFilterOrderByApprove', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterOrderByReserve(field) {
      this.$store.dispatch('crm_proposals/listviewFilterOrderByReserve', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    listviewIconOrderBySent(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBySent.column === filterField) {
        if (this.listviewOrderBySent.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    listviewIconOrderByApprove(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderByApprove.column === filterField) {
        if (this.listviewOrderByApprove.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    listviewIconOrderByReserve(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderByReserve.column === filterField) {
        if (this.listviewOrderByReserve.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('crm_proposals/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async fetchListView() {
      await this.$store.dispatch('crm_proposals/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    openModalDetailByRow(row) {
      this.showProcessoModalDetail = true

      if (row !== undefined && row !== null) {
        this.rowModalAction = row || {}
      } else {
        this.$refs.processoModalDetail.getModalData()
      }
    },
    async cancelNewProposal() {
      const actionModal = new Promise(resolve => {
        showModalMessage(resolve)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.$store.commit('crm_proposals_form/setUpdateFormData', {})

          if (this.backToVisitas === true) {
            this.$router.push({ name: 'crm-visitas-listview' })
          } else {
            this.$store.dispatch('crm_proposals/setShowForm', false).catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.$store.dispatch('crm_proposals/setShowListVisits', false).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        }
      })
    },
    async saveNewProposal() {
      const self = this

      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formProposal.$refs.formSaveProposalValidator.validate()
          .then(valid => {
            if (valid) {
              let vlEntrega = this.$store.getters['crm_proposals_form/form_data'].sw131s26
              vlEntrega = vlEntrega.replace(/\./g, '').replace(',', '.')

              if (Number(vlEntrega) > 0) {
                if (this.$refs.formProposal.aFilesProposal.length > 0 && this.$refs.formProposal.aFilesProposal[0].name !== '') {
                  self.$root.$emit('app::loading', true)
                  this.$store.dispatch('crm_proposals_form/saveProposal', { reforcos: this.$refs.formProposal.aReforcos, files: this.$refs.formProposal.aFilesProposal }).then(res => {
                    self.$root.$emit('app::loading', false)
                    self.showMsgSuccessRequest(res)

                    this.$store.dispatch('crm_proposals/setShowForm', false)
                    this.$store.dispatch('crm_proposals/setShowListVisits', false)

                    const urlPrevRoute = '/crm/leads/propostas'
                    if (urlPrevRoute === this.$router.currentRoute.fullPath) {
                      this.$router.go()
                    } else {
                      this.$router.push({ name: 'crm-proposals-listview' }).then(() => {
                        this.$root.$emit('app::loading', false)
                      })
                    }

                    this.$store.dispatch('crm_proposals/fetchListView')
                  }).catch(error => {
                    self.$root.$emit('app::loading', false)
                    self.showMsgErrorRequest(error)
                  })
                } else {
                  this.$swal({
                    title: '',
                    html: `${this.$t('Ao submeter sem anexar um documento da proposta digitalizada, a aprovação passa a depender dos {cargo}. Deseja continuar?', { cargo: `${this.$store.getters['crm_proposals_form/cargo']}` })}`,
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: this.$t('Continuar sem documento'),
                    cancelButtonText: this.$t('Voltar'),
                    customClass: {
                      cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                      confirmButton: 'btn btn-primary ml-lg-1 btn-xs-block',
                    },
                    buttonsStyling: false,
                  }).then(async result => {
                    if (result.value) {
                      self.$root.$emit('app::loading', true)
                      this.$store.dispatch('crm_proposals_form/saveProposal', { reforcos: this.$refs.formProposal.aReforcos, files: this.$refs.formProposal.aFilesProposal }).then(res => {
                        self.$root.$emit('app::loading', false)
                        self.showMsgSuccessRequest(res)

                        this.$store.dispatch('crm_proposals/setShowForm', false)
                        this.$store.dispatch('crm_proposals/setShowListVisits', false)

                        const urlPrevRoute = '/crm/leads/propostas'
                        if (urlPrevRoute === this.$router.currentRoute.fullPath) {
                          this.$router.go()
                        } else {
                          this.$router.push({ name: 'crm-proposals-listview' }).then(() => {
                            this.$root.$emit('app::loading', false)
                          })
                        }

                        this.$store.dispatch('crm_proposals/fetchListView')
                      }).catch(error => {
                        self.$root.$emit('app::loading', false)
                        self.showMsgErrorRequest(error)
                      })
                    }
                  })
                }
              } else {
                this.showMsgErrorRequest(new Error(this.$t('O valor do negócio não está certo com os valores de entrega!')))
              }
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    cancelReservation(flagCancel) {
      if (flagCancel === true) {
        this.showFormCancelReservation = true
      } else {
        this.showMsgErrorRequest(new Error(this.$t('O imóvel já não se encontra no estado reservado.')))
      }
    },
    cancelProposal(flagCancel) {
      if (flagCancel === true) {
        this.showFormCancelProposal = true
      } else {
        this.showMsgErrorRequest(new Error(this.$t('A proposta já está aceite pelo que não a pode cancelar, solicite a intervenção do seu {cargo}', { cargo: `${this.$store.getters['crm_proposals_form/cargo']}` })))
      }
    },
    async counterProposal(flagCounter) {
      if (flagCounter === 0) {
        await this.$store.dispatch('crm_proposals_form/newFlowProposalModal', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01) }).then(async res => {
          if (res === true) {
            this.showFormCounterProposal = true
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else if (flagCounter === 1) {
        this.showMsgErrorRequest(new Error(this.$t('O imóvel já não se encontra no estado ativo.')))
      } else if (flagCounter === 2) {
        this.showMsgErrorRequest(new Error(this.$t('O consultor angariador tem neste momento uma proposta aceite para este imóvel.')))
      }
    },
    uploadDocuments() {
      this.showFormUploadDocs = true
    },
    async getActivityProposal() {
      await this.$store.dispatch('crm_proposals/loadActivityProposal', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01) }).then(async res => {
        if (res === true) {
          this.showFormActivity = true
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    openLeftSidebar(bOpen) {
      this.mqShallShowLeftSidebar = bOpen
    },
    async checkProposalsState(vlCleanAction) {
      this.processingAjax = true
      await this.$store.dispatch('crm_proposals/checkProposalsState', { cleanAction: vlCleanAction }).then(res => {
        if (res.proposals.length > 0 && Number(res.compraOnline) === 0) {
          this.refuseMultiple = 1
          if (this.countProposal === 0) {
            this.$swal({
              title: '',
              html: `${this.$t('Existem mais')} ${res.proposals.length} ${this.$t('propostas em negociação para esta angariação. Para poder aceitar esta proposta, tem que recusar todas as outras propostas em negociação para este imóvel. Pretende recusar?')}`,
              icon: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('Sim'),
              cancelButtonText: this.$t('Não'),
              customClass: {
                cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                confirmButton: 'btn btn-primary ml-lg-1 btn-xs-block',
              },
              buttonsStyling: false,
            }).then(async result => {
              if (result.value) {
                this.getModalRefuseProposal(1, res.proposals[0].sw130s01)
              }
            })
          } else {
            this.getModalRefuseProposal(1, res.proposals[0].sw130s01)
          }
        } else if (Object.keys(this.$refs.formAcceptProcesso.aFilesUpload).length > 0) {
          this.uploadDocs()
        } else {
          this.submitAcceptProposal()
        }
      }).catch(error => {
        this.processingAjax = false
        this.showMsgErrorRequest(error)
      })
    },
    async uploadDocs() {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('crm_proposals_events/uploadDocs', { files: this.$refs.formAcceptProcesso.aFilesUpload }).then(async result => {
        this.$refs.formAcceptProcesso.resetFieldUpload()

        Object.keys(result).forEach(index => {
          if (result[index].error === false) {
            this.resumeErrorUpload.push(result[index])
          } else {
            this.$refs.formAcceptProcesso.aFilesProposal[index].name = result[index].filename
          }
        })
        this.submitAcceptProposal()
      }).catch(error => {
        this.resetFieldUpload()
        this.showMsgErrorRequest(error)
      })
    },
    async submitAcceptProposal() {
      this.$root.$emit('app::loading', true)

      let filename = ''
      const aFiles = []
      let sFilesUpload = ''
      const aLegendas = []
      let sLegendasFiles = ''

      if (this.$refs.formAcceptProcesso.aFilesProposal.length > 0) {
        this.$refs.formAcceptProcesso.aFilesProposal.forEach((file, index) => {
          if (Number(index) === 0) {
            filename = file.name
          }

          aFiles.push(file.name)

          if (typeof file.legend !== 'undefined') {
            aLegendas.push(file.legend)
          } else {
            aLegendas.push('')
          }
        })

        sFilesUpload = JSON.stringify(aFiles)
        sLegendasFiles = JSON.stringify(aLegendas)
      }

      await this.$store.dispatch('crm_proposals/acceptProposal', {
        sw131s20: filename, refuseMultiple: this.refuseMultiple, sFiles: sFilesUpload, sLegendas: sLegendasFiles,
      }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (res === true) {
          let actionProposal = 'all'
          if (Number(this.proposalDetail.iWhoami) === 1 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 5) {
            actionProposal = 'sent'
          } else if (Number(this.proposalDetail.iWhoami) === 2 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 4) {
            actionProposal = 'received'
          }

          await this.$store.dispatch('crm_proposals/flowProposalObjects', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01), action: actionProposal }).catch(error => {
            this.showMsgErrorRequest(error)
          })

          this.showFormAcceptProcess = false

          if (this.refuseMultiple) {
            this.$swal({
              title: this.$t('O proprietário aceitou'),
              html: this.$t('Operação concluída com sucesso!'),
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: this.$t('Fechar'),
              customClass: {
                confirmButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
            }).then(async result => {
              if (result.value) {
                // Não faz nada
              }
            })
          }

          this.$refs.detailProposal.$refs.detailProposalScroll.$el.scrollTop = 0
          this.$refs.formAcceptProcesso.resetData()

          await this.$store.dispatch('crm_proposals/fetchListView')

          this.refuseMultiple = 0
        } else {
          this.showMsgErrorRequest(new Error(res.msg))
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    async getModalRefuseProposal(vlRefuseMultiple, vlSW130s01) {
      await this.$store.dispatch('crm_proposals/getModalRefuseProposal', { refuseMultiple: vlRefuseMultiple, sw130s01: vlSW130s01 }).then(async res => {
        if (res.error === 0) {
          this.countProposal += 1
          this.modalDataRefuse = res.data
          this.showModalRefuseProposal = true

          setTimeout(() => {
            this.$root.$emit('app::loading', false)

            if (this.showModalRefuseProposal === true) {
              this.$refs.modalRefuseProposal.showModal().then(result => {
                if (result === true) {
                  this.$root.$emit('app::loading', false)
                }
              })
            }
          }, 800)
        }
      }).catch(error => {
        this.processingAjax = false
        this.showMsgErrorRequest(error)
      })
    },
    async submitRefuseProposal() {
      let filename = ''
      const aFiles = []
      let sFilesUpload = ''
      const aLegendas = []
      let sLegendasFiles = ''
      let dataRefuse = ''
      let motivo = ''
      let idTemp = ''

      if (typeof this.$refs.modalRefuseProposal !== 'undefined' && this.$refs.modalRefuseProposal.aFilesProposal.length > 0) {
        this.$refs.modalRefuseProposal.aFilesProposal.forEach((file, index) => {
          if (Number(index) === 0) {
            filename = file.name
          }

          aFiles.push(file.name)

          if (typeof file.legend !== 'undefined') {
            aLegendas.push(file.legend)
          } else {
            aLegendas.push('')
          }
        })

        sFilesUpload = JSON.stringify(aFiles)
        sLegendasFiles = JSON.stringify(aLegendas)
      }

      if (typeof this.$refs.formRefuseProposal !== 'undefined' && this.$refs.formRefuseProposal.aFilesProposal.length > 0) {
        this.$refs.formRefuseProposal.aFilesProposal.forEach((file, index) => {
          if (Number(index) === 0) {
            filename = file.name
          }

          aFiles.push(file.name)

          if (typeof file.legend !== 'undefined') {
            aLegendas.push(file.legend)
          } else {
            aLegendas.push('')
          }
        })

        sFilesUpload = JSON.stringify(aFiles)
        sLegendasFiles = JSON.stringify(aLegendas)
      }

      if (typeof this.$refs.modalRefuseProposal !== 'undefined') {
        dataRefuse = this.$refs.modalRefuseProposal.vlData
        motivo = this.$refs.modalRefuseProposal.vlMotivo
        idTemp = btoa(this.modalDataRefuse.sw130s01)
      } else if (typeof this.$refs.formRefuseProposal !== 'undefined') {
        dataRefuse = this.$refs.formRefuseProposal.vlData
        motivo = this.$refs.formRefuseProposal.vlMotivo
      }

      await this.$store.dispatch('crm_proposals/refuseProposal', {
        sw131s16: dataRefuse, sw131s17: motivo, sw131s20: filename, sw130s01Temp: idTemp, refuseMultiple: this.refuseMultiple, sFiles: sFilesUpload, sLegendas: sLegendasFiles,
      }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (res.error !== 1) {
          if (this.refuseMultiple === 1) {
            this.$refs.modalRefuseProposal.hideModal()
          }

          await this.showMsgSuccessRequest(res)

          let actionProposal = 'received'
          if (Number(this.proposalDetail.iWhoami) === 1 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 5) {
            actionProposal = 'sent'
          } else if (Number(this.proposalDetail.iWhoami) === 2 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 4) {
            actionProposal = 'received'
          }

          await this.$store.dispatch('crm_proposals/flowProposalObjects', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01), action: actionProposal }).catch(error => {
            this.showMsgErrorRequest(error)
          })

          this.showFormRefuseProcess = false

          if (this.refuseMultiple === 1) {
            await this.checkProposalsState('')
          } else {
            this.$refs.detailProposal.$refs.detailProposalScroll.$el.scrollTop = 0
            await this.$store.dispatch('crm_proposals/fetchListView')
          }
        } else {
          this.showMsgErrorRequest(new Error(res.msg))
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    async submitCancelProposal() {
      let filename = ''
      const aFiles = []
      let sFilesUpload = ''
      const aLegendas = []
      let sLegendasFiles = ''
      let motivo = ''
      const newStatus = 1

      if (typeof this.$refs.formCancelProposal !== 'undefined' && this.$refs.formCancelProposal.aFilesProposal.length > 0) {
        this.$refs.formCancelProposal.aFilesProposal.forEach((file, index) => {
          if (Number(index) === 0) {
            filename = file.name
          }

          aFiles.push(file.name)

          if (typeof file.legend !== 'undefined') {
            aLegendas.push(file.legend)
          } else {
            aLegendas.push('')
          }
        })

        sFilesUpload = JSON.stringify(aFiles)
        sLegendasFiles = JSON.stringify(aLegendas)
      }

      if (typeof this.$refs.formCancelProposal !== 'undefined') {
        motivo = this.$refs.formCancelProposal.vlMotivo
      }

      await this.$store.dispatch('crm_proposals/cancelProposal', {
        sw131s17: motivo, sw131s20: filename, sw012s64: newStatus, sFiles: sFilesUpload, sLegendas: sLegendasFiles,
      }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (res.error !== 1) {
          await this.showMsgSuccessRequest(res)

          let actionProposal = 'all'
          if (Number(this.proposalDetail.iWhoami) === 1 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 5) {
            actionProposal = 'sent'
          } else if (Number(this.proposalDetail.iWhoami) === 2 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 4) {
            actionProposal = 'received'
          }

          await this.$store.dispatch('crm_proposals/flowProposalObjects', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01), action: actionProposal }).catch(error => {
            this.showMsgErrorRequest(error)
          })

          this.showFormCancelProposal = false
          this.openLeftSidebar(false)
          this.$refs.formCancelProposal.resetData()
          await this.$store.dispatch('crm_proposals/setShowDetail', false)
          await this.$store.dispatch('crm_proposals/fetchListView')
        } else {
          this.showMsgErrorRequest(new Error(res.msg))
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    async submitDocsProposal() {
      let filename = ''
      const aFiles = []
      let sFilesUpload = ''
      const aLegendas = []
      let sLegendasFiles = ''

      if (typeof this.$refs.formUploadDocsProposal !== 'undefined' && this.$refs.formUploadDocsProposal.aFilesProposal.length > 0) {
        this.$refs.modalRefuseProposal.aFilesProposal.forEach((file, index) => {
          if (Number(index) === 0) {
            filename = file.name
          }

          aFiles.push(file.name)

          if (typeof file.legend !== 'undefined') {
            aLegendas.push(file.legend)
          } else {
            aLegendas.push('')
          }
        })

        sFilesUpload = JSON.stringify(aFiles)
        sLegendasFiles = JSON.stringify(aLegendas)
      }

      await this.$store.dispatch('crm_proposals/addDocsProposal', {
        sw131s20: filename, sFiles: sFilesUpload, sLegendas: sLegendasFiles, addDocsToProposal: 1,
      }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (res === true) {
          let actionProposal = 'all'
          if (Number(this.proposalDetail.iWhoami) === 1 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 5) {
            actionProposal = 'sent'
          } else if (Number(this.proposalDetail.iWhoami) === 2 || Number(this.proposalDetail.iWhoami) === 3 || Number(this.proposalDetail.iWhoami) === 4) {
            actionProposal = 'received'
          }

          await this.$store.dispatch('crm_proposals/flowProposalObjects', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01), action: actionProposal }).catch(error => {
            this.showMsgErrorRequest(error)
          })

          this.showFormUploadDocs = false
        } else {
          this.showMsgErrorRequest(new Error(res.msg))
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    closeModalDetail() {
      if (this.backToVisitas === true) {
        this.$router.push({ name: 'crm-visitas-listview' })
      } else {
        this.showDetail = false
      }
    },
    listViewSavePositionScroll() {
      this.sPostionListview = {
        scrollTop: this.$refs.listviewScroll.$el.scrollTop,
        scrollLeft: this.$refs.listviewScroll.$el.scrollLeft,
      }
    },
    async setProposalDetail(show, idProposal) {
      if (show === true) {
        let vlAction = this.tabSelectFilter
        if (vlAction === 'approve') {
          vlAction = 'all'
        } else if (vlAction === 'reserve') {
          vlAction = 'all'

          if (this.filterSelected.estadoReserve.value === 1) {
            vlAction = 'received'
          } else if (this.filterSelected.estadoReserve.value === 2) {
            vlAction = 'sent'
          }
        }

        await this.$store.dispatch('crm_proposals/previewModalProposal', { sw130s01: idProposal, action: vlAction }).then(result => {
          if (result) {
            this.$store.dispatch('crm_proposals/setProposalDetail', { showDetail: show }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    async loadFilterCustomQuery(paramsQuery, openViewDetail) {
      return new Promise(resolve => {
        let filterCustomQuery = false
        let sFindTabSelectFilter = ''

        if ((paramsQuery !== null) && (paramsQuery !== undefined)) {
          if ('recebidas' in paramsQuery) {
            sFindTabSelectFilter = 'received'
            filterCustomQuery = true
          }

          if ('efetuadas' in paramsQuery) {
            sFindTabSelectFilter = 'sent'
            filterCustomQuery = true
          }

          if ('aprovar' in paramsQuery) {
            sFindTabSelectFilter = 'approve'
            filterCustomQuery = true
          }

          if ('reservadas' in paramsQuery) {
            filterCustomQuery = true
          }

          if ((filterCustomQuery === true) && (sFindTabSelectFilter !== '')) {
            const tabSelect = this.filterData.tabsListview.find(tab => tab.name === sFindTabSelectFilter)
            if (tabSelect !== undefined) {
              this.$store.commit('crm_proposals/setTabSelectFilter', tabSelect.name)
            }
          }

          if (('viewDetail' in this.routeParamsQuery) && (this.routeParamsQuery.viewDetail !== '')) {
            if (openViewDetail === true) {
              this.mqShallShowLeftSidebar = false

              this.setProposalDetail(true, this.routeParamsQuery.viewDetail)
            }
          }
        }

        if (filterCustomQuery === false) {
          this.$store.commit('crm_proposals/initStates')
        } else {
          this.$router.replace({ query: {} })
        }

        resolve({ filterCustomQuery, sFindTabSelectFilter })
      })
    },
  },
  setup() {
    const CRM_PROPOSALS_MODULE_NAME_LIST = 'crm_proposals'
    const CRM_PROPOSALS_MODULE_NAME_EVENTS = 'crm_proposals_events'
    const CRM_VISITAS_MODULE_NAME = 'crm_visitas_listview'

    if (!store.hasModule(CRM_PROPOSALS_MODULE_NAME_LIST)) {
      store.registerModule(CRM_PROPOSALS_MODULE_NAME_LIST, crmProposalsListModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_PROPOSALS_MODULE_NAME_LIST)) store.unregisterModule(CRM_PROPOSALS_MODULE_NAME_LIST)
      })
    }

    if (!store.hasModule(CRM_PROPOSALS_MODULE_NAME_EVENTS)) {
      store.registerModule(CRM_PROPOSALS_MODULE_NAME_EVENTS, crmProposalsEventsModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_PROPOSALS_MODULE_NAME_EVENTS)) store.unregisterModule(CRM_PROPOSALS_MODULE_NAME_EVENTS)
      })
    }

    if (!store.hasModule(CRM_VISITAS_MODULE_NAME)) {
      store.registerModule(CRM_VISITAS_MODULE_NAME, crmModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_VISITAS_MODULE_NAME)) store.unregisterModule(CRM_VISITAS_MODULE_NAME)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const { route } = useRouter()
    const paramsUrl = route.value.params
    const routeParamsQuery = computed(() => route.value.query)

    const sPostionListview = {
      scrollTop: 0,
      scrollLeft: 0,
    }

    const ModalReportVisita = () => import(/* webpackChunkName: "crm-visitas-modal-report" */ /* webpackPrefetch: true */ '../../visitas/listview/modal/report.vue')
    const ModalRefuseProposal = () => import(/* webpackChunkName: "crm-proposals-detail-modal-refuse" */ /* webpackPrefetch: true */ '../form/modal/refuseMultiple.vue')

    return {
      paramsUrl,
      mqShallShowLeftSidebar,
      ModalReportVisita,
      ModalRefuseProposal,
      sPostionListview,
      routeParamsQuery,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

.pull-down-container ::v-deep{
  .pull-down-header{
    background-color:inherit!important;
  }
  .pull-down-content{
    font-family:inherit!important;
    color:inherit!important;
    font-size:inherit!important;
  }
}
</style>
